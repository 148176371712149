/* eslint-disable max-len */
import { DocumentIcon, MentoringIcon, ReliefIcon, TrainingIcon } from '@svg';
import React from 'react';
import xw from 'twin.macro';

const styles = {
  blockquote: xw`relative pl-8`,
  'blockquote-bg-1': xw`bg-blue-300 dark:bg-indigo-200`,
  'blockquote-bg-2': xw`bg-gray-300 dark:bg-blue-400`,
  'blockquote-deco': xw`absolute h-full w-2 left-0`,
  heading: xw`mt-2 text-3xl font-extrabold leading-10 tracking-tight sm:text-4xl sm:leading-10`,
  iconContainer: xw`
    overflow-hidden flex items-center justify-center w-12 h-12 text-brand-blue bg-brand-blue mb-4
  `,
  subHeading: xw`text-lg md:text-2xl leading-6 font-bold`,
};

function Component() {
  return (
    <div className="py-16 bg-gray-100 lg:pt-28 dark:bg-gray-800">
      <div className="px-4 mb-12 text-center sm:px-6 lg:px-8">
        <p className="text-base font-semibold leading-6 tracking-wide uppercase text--color-brand-1">
          Services
        </p>
        <h2 className="text--color-heading-1" css={[styles.heading]}>
          Emergency Response Project Management
        </h2>
      </div>
      <ul className="grid gap-16 px-4 text-center md:grid-cols-2 md:text-left">
        <span>
          <li className="max-w-md mx-auto mb-20">
            <span className="flex justify-center w-full md:block">
              <TrainingIcon className="w-20 h-20 mb-4 text-brand-blue" />
            </span>
            <h3
              className="mb-4 text--color-heading-1"
              css={[styles.subHeading]}
            >
              Training Provider
              <br />
              <div className="mt-4 text-lg leading-6">
                Classroom, web based virtual online and on the job capacity
                building training
              </div>
            </h3>
            <ul className="text-left space-y-7">
              <li>
                <blockquote css={[styles.blockquote]}>
                  <div
                    aria-hidden
                    css={[styles['blockquote-deco'], styles['blockquote-bg-1']]}
                  />
                  <p className="text-lg text-center text-black md:text-left dark:text-white">
                    Providing technical and soft skills courses primarily in
                    emergency response. Course covers humanitarian and
                    environmental disaster prevention, preparation and response
                    project management approaches . Course materials in
                    reference to UNDSR, and PMI - PMBoK life cycle, knowledge
                    areas and processes.
                  </p>
                </blockquote>
              </li>
              <li>
                <blockquote css={[styles.blockquote]}>
                  <div
                    aria-hidden
                    css={[styles['blockquote-deco'], styles['blockquote-bg-2']]}
                  />
                  <p className="text-lg text-center text-black md:text-left dark:text-white">
                    The new normal disaster risk response (DRR) construction
                    project management approach methodology are universally
                    adopted during ongoing public health and environmental
                    crisis in every United Nations Partners projects. Further
                    endorsed by project management institute (PMI) , UN WHO,
                    CIDB Malaysia, Ministry of Human Resources, Malaysia (MoR)
                    standard SOP and guideline practices in the construction
                    industry.
                  </p>
                </blockquote>
              </li>
              <li>
                <blockquote css={[styles.blockquote]}>
                  <div
                    aria-hidden
                    css={[styles['blockquote-deco'], styles['blockquote-bg-1']]}
                  />
                  <p className="text-lg text-center text-black md:text-left dark:text-white">
                    On the job capacity training is provided for IDP and refugee
                    camp construction including CCCM- Camp Care and Maintenance
                    training program and WASH infrastructure for camp management
                    partners and their technical team in line with UN global
                    CCCM SOP and guideline practices.
                  </p>
                </blockquote>
              </li>
            </ul>
          </li>

          <li className="relative flex flex-col max-w-md mx-auto mb-20">
            <span className="flex justify-center w-full md:block">
              <ReliefIcon className="w-20 h-20 mb-4 text-brand-blue" />
            </span>
            <h3
              className="mb-4 text--color-heading-1"
              css={[styles.subHeading]}
            >
              Humanitarian Relief Field Mission
            </h3>
            <ul className="max-w-md mx-auto text-left">
              <li>
                <blockquote css={[styles.blockquote]}>
                  <div
                    aria-hidden
                    css={[styles['blockquote-deco'], styles['blockquote-bg-1']]}
                  />
                  <p className="text-lg text-center text-black md:text-left dark:text-white">
                    Undertake short term assignment (STA) consultancy services
                    in post emergency crisis project management for united
                    nation humanitarian partners. Expertise in;
                  </p>
                  <ul className="pt-6 pl-4 space-y-2 list-decimal text-md text--color-body-2">
                    <li>Shelter Rehabilitation and new construction</li>
                    <li>WASH Infrastructure projects (Water & Sanitation)</li>
                    <li>
                      Emergency Camps for Internally Displaced Peoples and
                      Refugees
                    </li>
                    <li>
                      Community Development Projects during reconstruction and
                      rebuilding damaged infrastructure
                    </li>
                    <li>
                      Program management - Planning and execution major
                      humanitarian relief project management
                    </li>
                  </ul>
                </blockquote>
              </li>
            </ul>
          </li>
        </span>

        <span>
          <li className="relative flex flex-col max-w-md mx-auto mb-20">
            <span className="flex justify-center w-full md:block">
              <DocumentIcon className="w-20 h-20 mb-4 text-brand-blue" />
            </span>
            <h3
              className="mb-4 text--color-heading-1"
              css={[styles.subHeading]}
            >
              Technical Documents
              <br />
              <div className="mt-4 text-lg leading-6">
                Emergency Response Engineering And Construction Management
              </div>
            </h3>
            <ul className="text-left space-y-7">
              <li>
                <blockquote css={[styles.blockquote]}>
                  <div
                    aria-hidden
                    css={[styles['blockquote-deco'], styles['blockquote-bg-1']]}
                  />
                  <p className="text-lg text-center text-black md:text-left dark:text-white">
                    Undertake services in technical audit, assessment, and
                    preparation of complete set project development documents
                    for construction humanitarian relief projects ( Shelter,
                    WASH and CCCM Camp construction) in conformance to
                    international humanitarian sphere standards, international
                    building and uniform code standard practices.
                  </p>
                </blockquote>
              </li>
              <li>
                <blockquote css={[styles.blockquote]}>
                  <div
                    aria-hidden
                    css={[styles['blockquote-deco'], styles['blockquote-bg-2']]}
                  />
                  <p className="text-lg text-center text-black md:text-left dark:text-white">
                    Provide technical support for programs, procurement units in
                    tender bid preparation and execution. Carry out QA-QC
                    inspection services with the project team during pre and
                    post construction and handover processes.
                  </p>
                </blockquote>
              </li>
            </ul>
          </li>

          <li className="max-w-md mx-auto">
            <span className="flex justify-center w-full md:block">
              <MentoringIcon className="w-20 h-20 mb-4 text-brand-blue" />
            </span>
            <h3
              className="mb-4 text--color-heading-1"
              css={[styles.subHeading]}
            >
              Technical Advisor And Mentoring
            </h3>
            <ul className="text-left space-y-7">
              <li>
                <blockquote css={[styles.blockquote]}>
                  <div
                    aria-hidden
                    css={[styles['blockquote-deco'], styles['blockquote-bg-1']]}
                  />
                  <p className="text-lg text-center text-black md:text-left dark:text-white">
                    Providing technical engineering and management advisory
                    services to united nation technical program department in
                    middle east and other country mission regions
                  </p>
                </blockquote>
              </li>
              <li>
                <blockquote css={[styles.blockquote]}>
                  <div
                    aria-hidden
                    css={[styles['blockquote-deco'], styles['blockquote-bg-2']]}
                  />
                  <p className="text-lg text-center text-black md:text-left dark:text-white">
                    Undertake construction contracts conflict and resolution
                    services with UN partners in projects arising during
                    construction, project claims and post construction and
                    closeout.
                  </p>
                </blockquote>
              </li>
              <li>
                <blockquote css={[styles.blockquote]}>
                  <div
                    aria-hidden
                    css={[styles['blockquote-deco'], styles['blockquote-bg-1']]}
                  />
                  <p className="text-lg text-center text-black md:text-left dark:text-white">
                    Provide close coordination with sr management, program and
                    procurement and finance department in any construction
                    related matters.
                  </p>
                </blockquote>
              </li>
              <li>
                <blockquote css={[styles.blockquote]}>
                  <div
                    aria-hidden
                    css={[styles['blockquote-deco'], styles['blockquote-bg-2']]}
                  />
                  <p className="text-lg text-center text-black md:text-left dark:text-white">
                    Provide project support and mentoring services to field
                    workers undertaking relief mission work and advisory
                    services to project stakeholders.
                  </p>
                </blockquote>
              </li>
              <li>
                <blockquote css={[styles.blockquote]}>
                  <div
                    aria-hidden
                    css={[styles['blockquote-deco'], styles['blockquote-bg-1']]}
                  />
                  <p className="text-lg text-center text-black md:text-left dark:text-white">
                    Support sr management and program and donors with technical
                    proposal, presentations, reports upon request.
                  </p>
                </blockquote>
              </li>
              <li>
                <blockquote css={[styles.blockquote]}>
                  <div
                    aria-hidden
                    css={[styles['blockquote-deco'], styles['blockquote-bg-2']]}
                  />
                  <p className="text-lg text-center text-black md:text-left dark:text-white">
                    Provide random field travel and visit (on call basis).
                  </p>
                </blockquote>
              </li>
            </ul>
          </li>
        </span>
      </ul>
    </div>
  );
}

export const Services = Component;
